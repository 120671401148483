export default {
  error($bvToast, title, message) {
    this.toast($bvToast, title, message, 'danger')
  },

  success($bvToast, title, message) {
    this.toast($bvToast, title, message, 'success')
  },

  warning($bvToast, title, message) {
    this.toast($bvToast, title, message, 'warning')
  },

  toast($bvToast, title, message, type) {
    $bvToast.toast(message, {
      title,
      solid: false,
      variant: type,
      noAutoHide: false,
    })
  },
}
